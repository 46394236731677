// Primary
$sunrise: #ffcc40; // Our core brand color, meant for use in CTAs and illustration elements that support users on their mental health journey.
$navy-blue: #14467c; // Secondary CTAs
$sky-blue: #189adf; // Our main blue
$light-blue: #8ad5f0;
$grass-green: #2bb673;
$red: #d2444a;
$latte: #f1eadd;
$black: #000000;
$black-pearl: #171c21;
$white: #ffffff;
$gold: #dda827;

// Secondary - greys
$logo-grey: #666666;
$grey: #818181;
$light-grey: #bdbdba;
$pale-grey: #e2e2e2;
$grey-wash: #f9f9f9;
$bluish-grey: #707c8d;
$whisper-grey: #e7e7e7;
$link-blue: #0379bf;
$primary-sky-blue: #00a6db;

// Tints
$sunrise-80: #ffd56c;
$navy-blue-80: #2c5989;
$sky-blue-90: #091114;
$red-80: #db696e;
$latte-50: #f8f5ee;

$sunrise-60: #ffde90;
$navy-blue-70: #436b96;
$sky-blue-80: #33b8e2;
$grass-green-80: #57b989;
$red-70: #e48f92;

$sunrise-50: #ffe8b3;
$navy-blue-60: #5a7da3;
$sky-blue-70: #60cde5;
$grass-green-70: #81caa7;
$red-50: #edb4b7;

$sunrise-30: #fff0c6;
$navy-blue-50: #89a2be;
$sky-blue-60: #73d2e7;
$grass-green-50: #abdcc4;
$red-30: #f6dadb;

$sunrise-20: #fff2d6;
$navy-blue-40: #b9c8d8;
$sky-blue-50: #8adef0;
$grass-green-30: #d5ede2;
$red-20: #faeced;

$navy-blue-30: #d0dae5;
$sky-blue-40: #99e6f1;
$grass-green-20: #eaf6f0;

$sky-blue-30: #b3e8f4;
$sky-blue-20: #ccf0f8;
$sky-blue-10: #e5f7fb;
$azure-blue: #0087fb;
$zodiac-blue: #122a5b;
$lavendar-blue: #deebff;
$endeavour: #0056b3;

$darker-green: #2da76c;
$lime-green: #1e9d5d;

// Gradients
$sunrise-gradient: $sunrise 0%, #dda827 100%;
$sunset-gradient: $sunrise 0%, $white 100%;

@mixin gradient($direction, $gradient-color) {
  background: linear-gradient($direction, $gradient-color);
}
