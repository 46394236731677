@import '/theme/legacy/color_variables';
@import '/theme/2023Redesign/color_variables';

.suggestions_dropdown {
  z-index: 99;
  top: 15;
}

.input {
  outline: none;
  border: none;
  &:focus {
    outline: none;
  }
}

.dob_button:focus:not(:focus-visible) {
  box-shadow: 0 0 0 0.2rem #c3c8cc;
}

/*** Relationship Toggle styles ***/
.relation_toggle_border_left {
  border-left: 2px solid $platinum !important;
}

.relation_toggle_border {
  border-right: 2px solid $platinum !important;
  border-top: 2px solid $platinum !important;
  border-bottom: 2px solid $platinum !important;

  &:hover {
    background-color: $solitude-white;
  }
}

.relation_toggle_selected {
  border-color: $madison-blue !important;
}

.trailing_button {
  width: 9.2rem !important;
  min-width: 9.2rem !important;
}

.newsletter_error_container {
  border: 2px solid $red-50;
  width: 375px;
  height: 69px;
  position: relative;
  margin: 16px auto;

  @media (max-width: 767px) {
    width: 330px;
  }
}

.icon_container {
  // $red is a common color variable name with different values in the legacy and brand refresh
  background: #d2444a;
  width: 60px;
  height: 69px;
  position: absolute;
  top: -2px;
  left: -2px;
  border-radius: 30px/30px 100px 100px 30px;
}

.error_icon {
  position: absolute;
  padding: 20px 10px 10px 20px;
  width: 50px;
  height: 60px;
}

.error_message {
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  font-family: 'Gibson';
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  // $black is a common color variable name with different values in the legacy and brand refresh
  color: black;
  position: absolute;

  @media (max-width: 767px) {
    padding-left: 50px;
    padding-right: 20px;
  }
}

.newsletterForm_control_error {
  // $red is a common color variable name with different values in the legacy and brand refresh
  border: 1px solid #d2444a !important;
}
