@import '/theme/2023Redesign/color_variables';
@import '/theme/legacy/responsive';

// ********* BRAND LOGO *********** //

.brand_logo_container {
  margin: 16px;
  text-align: center;
}

// ********* LOGIN/SIGNUP *********** //

.login_page_main_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.login_page_top_container {
  padding-top: 24px;
}

.login_span_container {
  margin-bottom: 32px;
  margin-top: 32px;
}

.login_form_container {
  width: 400px;
  z-index: 1;

  @media (max-width: 448px) {
    padding: 0px 24px;
    width: 100%;
  }
}

.login_span {
  font-family: 'Gibson';
  font-weight: 400;
  font-style: normal;
  color: $black;
}

.booking_subheader {
  font-family: 'Gibson';
  margin-bottom: 18px;
}

.email_field {
  margin-bottom: 16px;
  box-sizing: border-box;
  padding-left: 23px;
  font-weight: 400 !important;
  color: $black;
  box-sizing: border-box;
  background: $white !important;
  border: 1px solid $logo-grey !important;
  border-radius: 25px !important;
  width: 331px;
  height: 48px;
  z-index: 1;
  &:not(:focus)::placeholder {
    color: $black;
  }
  &:focus::placeholder {
    color: transparent;
  }
}

.email_field_error {
  background-color: $red-20 !important;
  border: 1px solid $red;
}

.label {
  position: absolute;
  font-size: 10px;
  color: $grey;
  z-index: 2;
  top: 5px;
  left: 23px;
  transition: opacity 0.3s ease-in-out;

  // The opacity will be hidden by default
  opacity: 0;
}

.email_input_container {
  position: relative;

  &:focus-within {
    .label {
      opacity: 1;
    }
  }
}

.label_visible {
  opacity: 1;
}

.button {
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 28px 14px;
  width: 331px;
  height: 50px !important;
  left: 554px;
  top: 292px;
  color: $white !important;
  background: $navy-blue !important;
  border-radius: 48px;
  font-weight: 600 !important;
  border: none;
  box-shadow: none !important;
}

.button_login {
  @extend .button;
  margin-bottom: 32px;
}

.button_resend {
  @extend .button;
  margin-bottom: 24px;
}

.agreement_microcopy {
  font-style: normal;
  font-family: 'Gibson';
  padding: 0 10%;
  color: $black;
  text-align: center;
  margin-top: 32px;
  a {
    u {
      color: $black;
    }
  }
}

.button:focus {
  border: 2px solid $sky-blue-90;
}

.button:disabled {
  opacity: 1;
  .button_text {
    opacity: 0.75;
  }
}

.button:not(:disabled):hover {
  color: $black !important;
  border: none;
  background-color: $sunrise !important;
}

// ***** EmailSent ********** //

.email_sent_page_container {
  @media (max-height: 667px) {
    padding-top: 0px;
  }
  padding-top: 24px;
}

.email_sent_container {
  transition: height 2s;
  transition-delay: 1s;
  font-family: 'Gibson';
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  width: 100%;
  max-width: 450px;
  padding: 20px;
  z-index: 1;
}

.email_sent_image {
  margin-top: 80px;
  transition: margin-top 1s;
  @include media-breakpoint-down('sm') {
    margin-top: 39px;
  }
  @media screen and (max-height: 667px), screen and (max-width: 375px) {
    margin-top: 19px !important;
  }
}

.email_sent_image_no_margin {
  transition: margin-top 1s;
  margin-top: 0px;
}

.email_sent_icon {
  width: 78px;
  height: 64px;
}

.email_sent_text {
  margin: 0 auto;
}


// ********* Verify Otp Page  ********** //

.verify_otp_page_container {
  width: 100%;
  max-width: 444px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  @include media-breakpoint-down('md') {
    padding: 0 24px;
    padding-top: 16px;
  }
}

.verify_otp_form_container {
  transition: height 2s;
  transition-delay: 1s;
  font-family: 'Gibson';
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  width: 100%;
  max-width: 450px;
  z-index: 1;
}

// *********** TOAST STYLING *********//

.toast_div {
  width: 100vw;
  height: 20vh;
  transition: height 1s;
  @include media-breakpoint-down('sm') {
    height: 15vh;
  }
}

.empty_div {
  transition: height 1s;
  height: 0vh;
}

.toast_container {
  color: $black;
  border-radius: 10px;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  width: 343px !important;
  height: 81px !important;

  @include media-breakpoint-down('sm') {
    margin: 32px auto;
    padding: 0 !important;
  }

  @media (max-height: 667px) {
    margin: 12px auto;
  }

  div {
    padding: 0;

    div {
      background-color: transparent;
      border: none;

      div {
        position: relative;
        top: 0;
        width: 96%;
        margin: 0 auto;
        text-align: left;
        padding: 3px 0px 4px 27px;
      }
    }
  }

  button {
    opacity: 1;
    margin: 7px;
  }
}

.success_banner {
  @extend .toast_container;
  border: 1px solid $grass-green;
  background-color: $grass-green-30 !important;
  position: absolute;
  right: 0;

  @include media-breakpoint-down('sm') {
    position: relative;
  }
}

.error_banner {
  @extend .toast_container;
  background-color: $red-30 !important;
  border: 1px solid $red;
  position: absolute;
  right: 0;

  @include media-breakpoint-down('sm') {
    position: relative;
  }
}

.error_text {
  width: 261px;
  font-size: 13px;
  font-family: 'Gibson';
  line-height: 20px;
  letter-spacing: 0.005em;
  margin: 0 22px 12px 22px;
  color: $red;
}

//************ FOOTER ************//

.email_sent_footer_container {
  position: fixed;
  bottom: 0;
  width: 100vw;
  object-position: center;

  @include media-breakpoint-down('sm') {
    position: absolute;
    bottom: 0vh;
    transition: 1s bottom;
  }
}

.email_sent_footer_container_shifted {
  position: fixed;
  bottom: 0;
  width: 100vw;
  object-position: center;

  @include media-breakpoint-down('sm') {
    position: absolute;
    transition: 1s bottom;
    bottom: -10vh;
  }
}

.login_footer_container {
  position: fixed;
  bottom: 0;
  width: 100vw;
  object-position: center;

  @include media-breakpoint-down('sm') {
    position: static;
  }
}

.image_sun {
  width: 100%;

  @include media-breakpoint-down('sm') {
    img {
      opacity: 0;
    }

    object-position: center;
    height: 158px;
    background-image: url('/images/footer-top-bg.svg');
    background-position: center center;
    background-repeat: no-repeat;
  }
}

.image_trees_left {
  position: absolute;
  left: 0px;
  bottom: 11px;

  @include media-breakpoint-down('sm') {
    opacity: 0;
  }
}

.image_trees_right {
  position: absolute;
  right: 0px;
  bottom: 11px;

  @include media-breakpoint-down('sm') {
    opacity: 0;
  }
}

.extra_footer_height {
  height: 39px;
  margin-top: -5px;
  background-color: $grass-green;
  border: 2px solid $grass-green;
}
