//Colors
$madison-blue: #082b5f;
$oslo-grey: #8d8f93;
$light-sea-green: #26c4a8;
$coral-pink: #f98a83;
$solitude-white: #f4f6fa;
$dodger-blue: #0d85f4;
$black-russian: #26272b;
$black-shark: #202227;
$whisper-grey: #e6e6e6;
$azure: #3466b1;
$azure-radiance: rgba(13, 133, 244, 0.12);
$silver: #cccccc;
$border-light: #dee2e6;
$platinum-dark: #e2e2e2;
$platinum: #e7e7e7;
$lynx-white: #f7f7f7;
$mine-shaft: #2e2e2e;
$shark: #26272a;
$alice-blue: #f8faff;
$solitude: #ecf0f8;
$comet: #59647c;
$cod-grey: #1d1d1d;
$off-green: #e5f8f5;
$backdrop-green: rgba(13, 66, 56, 0.6);
$backdrop-border: #5f9b91;
$backdrop-background: rgba(46, 46, 46, 0.6);
$transparent-green: rgba(38, 196, 168, 0.08);
$glass-green: rgba(38, 196, 168, 0.3);
$periwinkle-grey: #cbd5eb;
$lavendar: #e8edf8;
$thriveworks-blue: #0779bf;
$slate-gray: #707c8d;
$cinder: #212529;
$sonic-silver: #777777;
$bright-yellow: #faa61a;
$cosmic-latte: #fffae9;
$dandelion: #ffd964;
$cadet-grey: #9ca6b2;
$alto: #dadada;
$ghost-white: #f7f9fb;
$wisp-pink: #fce7e7;
$light-gray: #e9ecef;
$cold-grey-blue: rgba($cinder, 0.04);
$clear-day: #e1f7f2;
$red: #e74c3c;
$success-muted: rgba($light-sea-green, 0.12);
$endeavour: #0056b3;
$zircon: #f7f9ff;
$pattens-white: #d7e9fd;
$light-cyan: #cbe9ff;
$sunglow: #ffc738;
$faux-water: #e0f7f2;
$bright-gray: #eaeaea;
$flower-blue: rgba(240, 243, 246, 0.24);
$off-white: #e5e5e5;
$white-smoke: #f3f3f3;
$pattens-blue: #d2eaff;
$black: #171c21;
$water: #c9e8f7;
$sunrise-30: #fff0c6;
$bright-blue: #0379bf;
$bubbles: #e8f7fd;
$charcoal: #666666;

$theme-colors: (
  primary: $madison-blue,
  secondary: $oslo-grey,
  success: $light-sea-green,
  success-muted: $success-muted,
  danger: $coral-pink,
  light: $solitude-white,
  info: $dodger-blue,
  dark: $black-russian,
  light-blue: $alice-blue,
  muted-grey: $alto,
  meta: $ghost-white,
  ghost-white: $ghost-white,
  bubbles: $bubbles,
  dandelion: $dandelion,
);

// these are used to create utilities, but we will be abandoning utility
// classes in favor of sass vars for access through individual files
$text-colors: (
  muted-dark: $black-shark,
  medium-black: $shark,
  date-dark: $cod-grey,
  date-light: $comet,
  app-black: $cinder,
  slate-gray: $slate-gray,
  black: $black,
  cinder: $cinder,
  charcoal: $charcoal,
);

// link variant color
$link-color: $dodger-blue;
