@use 'component_library/theme/colors' as colors;
@use 'component_library/theme/breakpoints' as responsive;

$medium-grey: colors.getColor('mediumGrey');
$black-pearl: colors.getColor('blackPearl');

.form_container {
  font-family: 'Gibson';
  font-weight: 400;
  font-style: normal;
}

.email_field {
  margin-bottom: 16px;
  box-sizing: border-box;
  padding-left: 23px;
  font-weight: 400 !important;
  color: $black-pearl;
  box-sizing: border-box;
  background: white !important;
  border: 1px solid colors.getColor('dimGrey') !important;
  border-radius: 25px;
  height: 40px;
  z-index: 1;

  &:not(:focus)::placeholder {
    color: colors.getColor('dimGrey');
  }
  &:focus::placeholder {
    color: transparent;
  }
}

.email_field_error {
  background-color: colors.getColor('red20') !important;
  border: 1px solid colors.getColor('caution-red');
}

.email_input_container {
  position: relative;

  &:focus-within {
    .label {
      opacity: 1;
    }
  }
}

.login_button {
  margin: auto;
  padding: 8px 28px;
  width: 100%;
  color: white;
  background: colors.getColor('navy') !important;
  border-radius: 48px;
  font-weight: 600;
  font-family: 'Gibson';
  font-size: 16px;
  border: none;
  box-shadow: none;

  &:focus {
    border: 2px solid colors.getColor('skyBlue90');
  }

  &:disabled {
    opacity: 0.5;
  }

  &:not(:disabled):hover {
    color: black !important;
    border: none;
    background-color: colors.getColor('sunrise') !important;
  }
}

// *********** TOAST STYLING *********//

.toast_container {
  color: black;
  border-radius: 10px;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  width: 343px !important;
  height: 81px !important;

  @include responsive.respond-to('sm', 'down') {
    margin: 32px auto;
    padding: 0 !important;
  }

  @media (max-height: 667px) {
    margin: 12px auto;
  }

  div {
    padding: 0;

    div {
      background-color: transparent;
      border: none;

      div {
        position: relative;
        top: 0;
        width: 96%;
        margin: 0 auto;
        text-align: left;
        padding: 3px 0px 4px 27px;
      }
    }
  }

  button {
    opacity: 1;
    margin: 7px;
  }
}

.error_banner {
  @extend .toast_container;
  background-color: colors.getColor('red20') !important;
  border: 1px solid colors.getColor('caution-red');

  position: absolute;
  right: 0;

  @include responsive.respond-to('sm', 'down') {
    position: relative;
  }
}

.error_text {
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.005em;
  margin: 0 22px 12px 22px;
  color: colors.getColor('caution-red');
}

.sign_in_divider {
  background-color: $medium-grey;
  height: 1px;
  width: 100%;
}

.sign_in_divider_content {
  font-size: 13px;
  font-family: 'Gibson';
  line-height: 19.5px;
  color: $medium-grey;
  margin: 0px 8px;
}

.sign_in_divider_container {
  margin: 24px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login_form_heading {
  color: $black-pearl;
  text-align: center;
  font-family: Gibson;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 16px;
}


// ******** New Input Field ********** //
// TODO: remove this style and replace the existing TextInput comp with new New Input Field comp when added
.new_input_field {
  font-family: 'Gibson';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: left;
  width: 100%;
  height: 50px;
  padding: 13px 10px;
  border-radius: 10px;
  border: 1px solid colors.getColor('dimGrey');
  font-weight: 400;

  &:hover {
    background: colors.getColor('navy10');
  }

  &:focus {
    outline: none;
    border-width: 2px;
    border-color: colors.getColor('summerSky');
    background: colors.getColor('white');
  }
}
