$breakpoints: (
  'xs': (
    'up': '1px',
    'down': '576px',
  ),
  'sm': (
    'up': '576px',
    'down': '768px',
  ),
  'md': (
    'up': '768px',
    'down': '992px',
  ),
  'lg': (
    'up': '992px',
    'down': '1200px',
  ),
  'xl': (
    'up': '1200px',
    'down': '1400px',
  ),
  '2xl': (
    'up': '1400px',
    'down': '1800px',
  ),
) !default;

// 'mobile first' design typically dictates that media queries be 'x'
// viewport and up', but we want both options at least available since we are
// building a dash.
// We will use the 'viewport & up' by default, with the option of specifying
// 'down' as an argument.
@mixin respond-to($breakpoint, $direction: null) {
  @if map-has-key($breakpoints, $breakpoint) {
    @if ($direction == 'up' or $direction == null) {
      @media (min-width: map-get($breakpoints, $breakpoint, 'up')) {
        @content;
      }
    }
    @if ($direction == 'down') {
      @media (max-width: map-get($breakpoints, $breakpoint, 'down')) {
        @content;
      }
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved for #{$breakpoint}. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
